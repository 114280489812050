<script>
import {
    ArrowUpIcon,
    ArrowDownIcon,
    MailIcon,
    PhoneIcon,
    MapPinIcon,
    LinkIcon, FacebookIcon, PhoneForwardedIcon, PrinterIcon, CornerRightDownIcon
} from 'vue-feather-icons';

import Vue from 'vue'
import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
import VueSocialSharing from 'vue-social-sharing'
import duppla from '@/duppla'

import { VueSvgGauge } from 'vue-svg-gauge'
import VueSpeedometer from "vue-speedometer"
import { setWithExpiration, getWithExpiration } from '@/services/storageResultsService';

const RESULTS_SAVE_TAG = process.env.VUE_APP_RESULTS_SAVE_TAG;


Vue.use(VueSocialSharing);

/**
 * Page-invoice component
 */
export default {
  components: {
    Navbar,
    Footer,
    ArrowUpIcon,
    ArrowDownIcon,
    MailIcon,
    PhoneIcon,
    MapPinIcon,
    LinkIcon,
    FacebookIcon,
    PhoneForwardedIcon,
    PrinterIcon,
    CornerRightDownIcon,
    VueSvgGauge, VueSpeedometer
  },
  data() {
    return {
      showScrArrow: true,
      countries: [],
      badToken: false,
      badPasted: false,
      validatingToken: false,
      token1: '',
      token2: '',
      token3: '',
      token4: '',
      token5: '',
      token6: '',
      emailChecked: false,
      showAll: true,
      canResendEmail: true,
      canResendEmailTimer: 0, 
      intervalId: null,
      updating: false,
      contact_email: '',
      contact_email_update: '',
      // opt_advice: '',
      opt_insurance: '',
      opt_screening: '',
      screeningForm: {},
      // contact_phone_country: null,
      // contact_phone: '',
      form: {},
      sharing: {
        url: window.location.href.split('/').slice(0, 3).join('/') + "/?id=",
        title: '¡Reduce tu riesgo frente al cáncer!',
        description: '¿Te imaginas vivir en un país donde nadie se pierde frente al cáncer? Da el primer paso para conocer y reducir tu riesgo frente al cáncer.',
        quote: 'La detección oportuna hace la diferencia, 90% de los cánceres tratados a tiempo son curables. - Duppla',
        hashtags: 'duppla,cancer'
      },
      urlReturn: window.location.href.split('/').slice(0, 3).join('/') + "/?id=",
      urlReturnConoce: "https://www.duppla.doctor/beneficiarios",
      networks: [
        { network: 'whatsapp', name: 'Whatsapp', icon: 'mdi mdi-whatsapp icon-lg', color: '#128c7e' },
        { network: 'email', name: 'Email', icon: 'mdi mdi-email icon-lg', color: '#333333' },
        { network: 'messenger', name: 'Messenger', icon: 'mdi mdi-facebook-messenger icon-lg', color: '#A334FA' }
        //{ network: 'facebook', name: 'Facebook', icon: 'mdi mdi-facebook icon-lg', color: '#1877f2' },
        //{ network: 'twitter', name: 'Twitter', icon: 'mdi mdi-twitter icon-lg', color: '#1da1f2' },
        //{ network: 'linkedin', name: 'LinkedIn', icon: 'mdi mdi-linkedin icon-lg', color: '#007bb5' },
        //{ network: 'reddit', name: 'Reddit', icon: 'mdi mdi-reddit icon-lg', color: '#ff4500' }
      ],
      result: null
/*result: 

  {
  "id": "recDrfjIgnWqdNOHb",
  "createdTime": "2022-09-30T23:24:25.000Z",
  "fields": {
    "identifier": 212,
    "contact_phone": "8180158128",
    "contact_phone_country": [
      "recAJYx0yQTMWWIbB"
    ],
    "record_date": "2022-09-30T23:24:25.165Z",
    "uuid": "d81e62bc-740c-4725-b902-d5a3a6df0404",
    "contact_email": "constantino.lojero@gmail.com",
    "patient_sex": "male",
    "user_age": "40",
    "symptom_concern": "no",
    "smoke": "no",
    "alcohol": "no",
    "exercise": "no",
    "eating_habit": "normal",
    "direct_relative": "idk",
    "weight": "65",
    "height": "176",
    "diabetes": "no",
    "hiperlipidemia": "no",
    "organization": [
      "recdpHQNUxZK2aVsV"
    ],
    "imc": 20.98,
    "smoking_index": 0,
    "general_base_rate": 0.00704682752815692,
    "helper_exercise_factor": 0,
    "helper_imc_factor": 0,
    "mama_ahf_factor": 0,
    "mama_ago_factor": 0,
    "mama_habits_factor": 0,
    "mama_factor": 0,
    "colon_ahf_factor": 0,
    "colon_habits_factor": 0,
    "colon_diab_factor": 0,
    "colon_hiperlip_factor": 0,
    "colon_factor": 0,
    "pulmon_factor": 0,
    "prostata_factor": 0,
    "prostata_ahf_factor": 0,
    "general_factor": 0,
    "general_rate": 0.00704682752815692,
    "mama_base_rate": 0,
    "mama_rate": 0,
    "colon_base_rate": 0.00092344062815955,
    "colon_rate": 0.00092344062815955,
    "pulmon_base_rate": 0.000297934902845353,
    "pulmon_rate": 0.000297934902845353,
    "prostata_base_rate": 0.000467920629840807,
    "prostata_rate": 0.000467920629840807,
    "menopause": "no",
    "general_base_rate_h": "7 de cada 1000",
    "pulmon_rate_f": "0%",
    "general_rate_h": "7 de cada 1000",
    "general_rate_min": 0.00450715,
    "general_rate_min_h": "1 de 2000",
    "general_rate_max": 0.04087160,
    "general_rate_max_h": "1 de 20",
    "contact_phone_country_iso": [
      "MX"
    ],
    "organization_name": [
      "Coca Cola Company"
    ],
    "messages": {
      "intro": [
        {
          "identifier": 5,
          "id": "CG_Factor_Riesgo_Masculino",
          "text": "7 de cada 1000 hombres con tu perfil de riesgo, serán diagnosticados con cáncer en los próximos 5 años. ",
          "status": "active",
          "category": "intro",
          "order": 5,
          "color": "primary"
        }
      ],
      "intro2": [
        {
          "identifier": 3,
          "id": "SINTOMAS_Positivo",
          "text": "IMPORTANTE: este cuestionario calcula el riesgo de personas sin síntomas ni preexistencia de cáncer. Dado que reportaste algunos síntomas, te sugerimos que consultes a un especialista, o te pongas en contacto con nosotros vía Onco.Chat",
          "status": "active",
          "category": "intro",
          "order": 3,
          "color": "info"
        }
      ],
      "factores_modificables": [
        {
          "identifier": 14,
          "id": "FM_Negativo",
          "text": "Ninguno. Con base en tus respuestas, llevas un estilo de vida saludable. ¡Sigue así para cuidar tu salud! ",
          "status": "active",
          "category": "factores_modificables",
          "order": 7,
          "color": "success"
        }
      ],
      "factores_no_modificables": [
        {
          "identifier": 22,
          "id": "Factor_NM_Edad",
          "text": "Conforme más experiencia adquirimos (más edad) las probabilidades de riego aumenta. Entre mayores seamos, más importantes son los esfuerzos de tamizaje que te explicaremos más adelante.",
          "status": "active",
          "category": "factores_no_modificables",
          "order": 5,
          "color": "warning"
        },
        {
          "identifier": 23,
          "id": "Factor_NM_AHF_Nose",
          "text": "El factor hereditario (presencia de cáncer en familia) nos ayuda a determinar con más presición el riesgo de padecer un cáncer. Te sugerimos que tengas conversaciones con tu familia en torno al tema, y -en caso de que descubras que ha habido presencia de cáncer en familiares inmediatos (padres o hermanos) o bien secundarios (primos, tíos, abuelos) CALL TO ACTION",
          "status": "active",
          "category": "factores_no_modificables",
          "order": 6,
          "color": "info"
        }
      ],
      "tamizaje":[
        {
          "identifier": 22,
          "id": "Tamizaje_Colon_Edad_45+",
          "field_name": "scr_fit",
          "screening_question": "¿Te has realizado el examen de sangre oculta en heces (método fit) en el último año?",
          "text_yes":"Felicidades Colon",
          "text": "A partir de los 45 años es recomendable iniciar con una detección temprana de cáncer de colon mediante una prueba de sangre oculta en heces una vez al año.",
          "status": "active",
          "category": "tamizaje",
          "order": 1,
          "color": "warning"
        },
        {
          "identifier": 22,
          "id": "Tamizaje_Fuma_20+50",
          "field_name": "scr_tctorax",
          "screening_question": "¿Te has realizado una tomografía (TAC) de tórax en el último año?",
          "text_yes":"Felicidades Torax",
          "text": "Dado tu perfil de edad y historial de consumo de tabaco es importante realizarte un estudio de TAC de tórax de baja dosis una vez al año. Esta práctica reduce el riesgo de muerte por cáncer de pulmón.",
          "status": "active",
          "category": "tamizaje",
          "order": 2,
          "color": "warning"
        }
      ]
    }
  }
}/**/

    }
  },
  props: {
    param: Object
  },
  computed: {
    token () {
      return this.token1 + this.token2 + this.token3 + this.token4 + this.token5 + this.token6
    },
    reqEmailValidation () {
      return this.result.fields.organization_req_email_validation
    },
    sMinValue () {
      return this.result.fields.general_rate_min*0.6
    },
    sMaxValue () {
      return this.result.fields.general_rate_max*1.05
    },
    sValue () {
      return this.result.fields.general_rate
    },
    sCustomSegment () {
      let segments = [
              this.sMinValue, 
              this.sMinValue + (this.sMaxValue - this.sMinValue)/4.5, 
              this.sMaxValue - (this.sMaxValue - this.sMinValue)/3,
              this.sMaxValue
              ]
      return segments
    },
    sCustomSegmentMessage () {
      let segments
      if (Number(this.result.fields.user_age) <= 39) {
        segments  =  [
                      {color: "#2eca8b", textColor:"#ffffff", label: "Verde", message: "Riesgo promedio en tu edad"}, 
                      {color: "#ffc107", textColor:"black", label: "Amarillo", message: "Riesgo encima al promedio en tu edad"}, 
                      {color: "#e43f52", textColor:"#ffffff", label: "Rojo", message: "Riesgo muy superior al promedio en tu edad"}
                    ]
      } else {
        segments  =  [
                      {color: "#ffc107", textColor:"black", label: "Amarillo", message: "Riesgo promedio en tu edad"}, 
                      {color: "#f17425", textColor:"#ffffff", label: "Naranja", message: "Riesgo encima al promedio en tu edad"}, 
                      {color: "#e43f52", textColor:"#ffffff", label: "Rojo", message: "Riesgo muy superior al promedio en tu edad"}
                    ]
      }
      return segments
    },
    sCustomSegmentColor () {
      let colors = []
      for(var i=0;i<this.sCustomSegmentMessage.length;i++) {
        colors.push(this.sCustomSegmentMessage[i].color)
      }
      //return ["#28a745", "#ffc107", "#dc3545"]
      return colors
    },
    scrCompleted () {
      for (let i=0;i<this.result.fields.messages.tamizaje.length;i++) {
        if (!this.result.fields.messages.tamizaje[i].screening_answer) {
          return false
        }
      }
      return true
    },
    scrYesAll () {
      for (let i=0;i<this.result.fields.messages.tamizaje.length;i++) {
        if (this.result.fields.messages.tamizaje[i].screening_answer === 'no') {
          return false
        }
      }
      return true
    }
  },
  methods: {
    showTamizaje (index) {
      if (index === 0) {
        return true
      } else {
        if (this.result.fields.messages.tamizaje[index-1].screening_answer) {
          return true
        }
      }

      // if this.result.fields.messages.tamizaje[index]
    },
    /*
    validateUuid() {
      duppla({ url: 'validateUuid?uuid=' + this.uuid, method: 'GET', data: null })
        .then(response => {
          console.log("ok: " + response.data.contact_firstname)
          this.userName = response.data.contact_firstname
          this.showContent = true
          setTimeout(() => {this.$refs.firstToken.focus()}, 100) 
        })
        .catch(error => {
          console.log(error)
          this.$router.push('/404')
        })
        .finally(() => {})
    },*/
    getCountries () {
      duppla({ url: 'countries', method: 'GET' })
        .then(response => {
          this.countries = response.data
        })
    },
    async sigin () {
      if(!this.validatingToken){
        if (this.$refs.emailcheck.checkValidity()) {
          this.validatingToken = true
          this.badToken = false
          this.badPasted = false

          const delay = ms => new Promise(res => setTimeout(res, ms));
          //await delay(1000);



          duppla({ url: 'questionnaire/validateEmail', method: 'PATCH', data: {token: this.token} })
            .then(response => {
              // console.log("*******************************************************************")
              // console.log("Validado: " + JSON.stringify(response.data, null, 2))
              this.result = response.data
              this.emailChecked = true
              
              setTimeout(() => {this.goto('emailchecked')}, 100) 

            })
            .catch(error => {
              let httpStatus = error.response.status.toString()
              console.error("Ha ocurrido un error inesperado: " + httpStatus)
              switch (error.response.status) {
                case 422: 
                  this.token1 = ''
                  this.token2 = ''
                  this.token3 = ''
                  this.token4 = ''
                  this.token5 = ''
                  this.token6 = ''
                  this.badToken = true
                  this.$refs.firstToken.focus()
                  break
                default:
                  this.token1 = ''
                  this.token2 = ''
                  this.token3 = ''
                  this.token4 = ''
                  this.token5 = ''
                  this.token6 = ''
                  this.badToken = true
                  this.$refs.firstToken.focus()
                  this.gTrackException('questionnaire-result -> sigin: ' + error, true)
              }
            })
            .finally(() => {
              this.validatingToken = false
            })

          /*
          if (this.token === "123456"){
            this.emailChecked = true
            this.validatingToken = false
            
            setTimeout(() => {this.goto('emailchecked')}, 100) 
          } else {
            this.validatingToken = false
            
            this.token1 = ''
            this.token2 = ''
            this.token3 = ''
            this.token4 = ''
            this.token5 = ''
            this.token6 = ''
            // this.token = ''
            this.badToken = true
            this.$refs.firstToken.focus()
          }
          */
        } else {
          this.$refs.emailcheck.reportValidity()
        }
      }
    },

    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input[type="number"]'))
      const index = inputs.indexOf(e.target)
      if (index < inputs.length - 1 && e.data) {
        inputs[index + 1].focus();
        inputs[index + 1].select();
      } else {
        //console.log("enviando a validar")
        this.sigin()
      }
    },
    onPaste(evt) {
      // console.log("pasted: " + evt.clipboardData.getData('text/plain'))
      this.badPasted = false
      let pasted = evt.clipboardData.getData('text/plain').trim()
      if (pasted.length !== 6 || isNaN(pasted)) {
        this.badPasted = true
      } else {
        let arrPasted = pasted.split("")
        this.token1 = arrPasted[0]
        this.token2 = arrPasted[1]
        this.token3 = arrPasted[2]
        this.token4 = arrPasted[3]
        this.token5 = arrPasted[4]
        this.token6 = arrPasted[5]
        setTimeout(() => {this.sigin()}, 100) 
      }
    },
    goto(refName) {
      // console.log('goto: ' + refName)
      // console.log(this.$refs)
      var element = this.$refs[refName];
      // console.log(element)
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    copyUrl() {
      navigator.clipboard.writeText(this.sharing.url).then(() => {
          this.$refs['copyModal'].show()
        },() => {
          this.gTrackException('questionnaire-result -> copyUrl: ' + error)
        }
      );
    },
    reSendEmail() {
      this.manageReSendEmailTimer(60)
      duppla({ url: 'questionnaire/resendEmail', method: 'POST'})
          .catch(error => {
            this.gTrackException('questionnaire-result -> reSendEmail: ' + error)
          })
    }, 
    update(data) {
      return new Promise((resolve, reject) => {
        this.updating = true
        //console.log('Actualizando: ' + JSON.stringify(data))
        duppla({ url: 'questionnaire', method: 'PATCH', data: data })
          .then(response => {
            //console.log("Actualizado: " + JSON.stringify(response.data, null, 2))
            resolve(response.data)
          })
          .catch(error => {
            this.gTrackException('questionnaire-result -> update: ' + error)
            reject(error)
          })
          .finally(() => {
            this.updating = false
            this.$forceUpdate();
          })
        })
    },
    screeningAnswer (e, message, index){
      /*
      var element = this.$refs['tamizaje' + index]
      var top = element[0].offsetTop;
      window.scrollTo(0, top);
      */
     let data = {}
     data[message.field_name] = e.target.value
     setWithExpiration(message.field_name, e.target.value)
     console.log("🚀 ~ screeningAnswer ~ data:", this.result.fields.messages.tamizaje)
     this.update(data)
    },
    updateProp(e) {
      let data = {}
      if (e.target.attributes.getNamedItem('at-type')) {
        if (e.target.attributes.getNamedItem('at-type').value === 'lookup'){
          data[e.target.name] = [e.target.value]
        } else {
          data[e.target.name] = e.target.value
        }
      } else {
        data[e.target.name] = e.target.value
      }
      this.update(data)
      /*
      if (e.target.name === 'opt_advice' && e.target.value === 'yes') {
        this.showAll = false
      }
      */
    },
    hideAll (){

    },
    updateEmail() {
      let form = this.$refs['emailForm']
      let data = {contact_email: this.contact_email_update}
      // console.log(form)

      if(form.checkValidity()){
        this.update(data).then((response) => {
          this.reSendEmail()
          this.contact_email = this.contact_email_update
          this.$refs['updateEmailModal'].hide()
          this.contact_email_update = ''
        }).finally(() => {})
      } else {
        form.reportValidity()
      }
    }, 
    manageReSendEmailTimer(time) {
      this.canResendEmail = false
      this.canResendEmailTimer = time
      var self = this
      this.intervalId = setInterval(function(){ 
        //console.log('contando: ' + self.canResendEmailTimer)
        if (self.canResendEmailTimer >= 1) {
          self.canResendEmailTimer -- 
        } else {
          self.canResendEmail = true
          clearInterval(self.intervalId)
        }
      }, 1000);
    },
    timerScrArrow() {
      // this.showScrArrow = !this.showScrArrow
      return null
    },
    print() {
      window.print()
    }
    /*
    showAllCheck(){
      if (this.$refs.form1.checkValidity()) {
        this.showAll = true
        this.update({contact_phone: this.contact_phone})
        setTimeout(() => {this.goto('tamizaje')}, 100) 
      } else {
        this.$refs.form1.reportValidity()
      }

    } */

  },
  created () {
    /**/

    this.result = this.param

    // Permitir que se vea toda la etiqueta en el widget
    /*
    if (this.result.fields.general_rate_min_h) {
      this.result.fields.general_rate_min_h = '\t' + this.result.fields.general_rate_min_h
    }
    */

    /**/
    this.urlReturn += this.result.fields.organization_uuid
    this.sharing.url += this.result.fields.organization_uuid
    this.sharing.url += '&o=' + this.result.id
    if(this.result.fields.reshare_level || this.result.fields.reshare_level === 0) {
      if (!isNaN(this.result.fields.reshare_level)) {
        // console.log('this.result.fields.reshare_level is number')
        let reshareLevel = Number(this.result.fields.reshare_level) + 1
        this.sharing.url += '&dl=' + reshareLevel
      }
    }

    duppla.defaults.headers.common['Authorization'] = 'Bearer ' + this.result.jwt
    
    this.getCountries()
    this.contact_email = this.result.fields.contact_email
    console.log("🚀 ~ created ~ this.result", this.result)
    this.result.fields.messages.tamizaje.forEach((element, index) => {
      const savedValue = getWithExpiration(element.field_name)
      console.log("🚀 ~ this.result.fields.messages.tamizaje.forEach ~ savedValue:", savedValue)
      if (savedValue) {
        element.screening_answer = savedValue
      }
    })
  },
  mounted() {
     this.timer = setInterval(() => this.timerScrArrow(), 700)
     if (!this.param) {
      const result = getWithExpiration(RESULTS_SAVE_TAG);
      console.log("🚀 ~ created ~ result:", result)
      if (result) {
        this.$router.push('/')
      } else {
        window.location.assign("https://www.duppla.doctor/beneficiarios");
      }
    }
  },
  unmounted() {
    // console.log("Questionnaire result unmounted!")
  },
  destroyed() {
    clearInterval(this.timer)
  }

}
</script>

<style>

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
      -moz-appearance:textfield; /* Firefox */
      appearance:textfield;
  }
  

  legend, label{
    font-size: 17px !important;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0
  }
  @media (max-width: 769px){
    .questionnarie_content label, .my_questionnaire p, .form-group .form-control, legend, .questionnarie_content .custom-select, .form-control, .questionnarie_content .input, .vue-monthly-picker .date-popover{
      font-size: 22px !important;
    }
    .questionnarie_content .card-title, .my_questionnaire .title{
      font-size: 30px !important;
    }
    .aumented_font {
      font-size: 22px !important;
    }
  }
</style>

<template>
<div>
    <Navbar :isIcons="false" :navOptions="false" :signupOptions="false" :corporateOptions="false"/>

    <!-- Invoice Start -->
    <section class="bg-invoice bg-light" v-if="result">
        <div class="container">
            <div class="row mt-0 pt-4 pt-sm-0 justify-content-center" ref="init">
                <div class="col-lg-10">
                    <div class="card shadow rounded border-0">
                        <div class="card-body">
                            <div class="invoice-top pb-4 border-bottom">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="logo-invoice mb-2">
                                          <h6>
                                            <span class="text-primary">¡</span>Bien hecho<span class="text-primary">!</span> 
                                            Así de rápido has dado un paso hacia la prevención.
                                            <br/>
                                            Ahora formas parte del grupo que elige vivir de manera más saludable<span class="text-primary">.</span> 
                                            <br/><br/>
                                            No te detengas y sigue las recomendaciones<span class="text-primary">.</span>&nbsp;<i class="mdi mdi-arrow-down text-primary"></i>
                                            <!--span class="text-primary">¡</span>Gracias de corazón<span class="text-primary">!</span>&nbsp;<i class="mdi mdi-heart text-danger"></i-->
                                          </h6>
                                        </div>
                                        <!--a href="javascript:void(0)" class="text-primary h6">
                                            <link-icon class="fea icon-sm text-muted mr-2"></link-icon>www.landrick.corp
                                        </a-->
                                    </div>
                                    <!--end col-->

                                    <!--div class="col-md-4 mt-4 mt-sm-0">
                                        <h5>Address :</h5>
                                        <dl class="row mb-0">
                                            <dt class="col-2 text-muted">
                                                <map-pin-icon class="fea icon-sm"></map-pin-icon>
                                            </dt>
                                            <dd class="col-10 text-muted">
                                                <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" class="video-play-icon text-muted">
                                                    <p class="mb-0"></p>
                                                    <p class="mb-0">Redding, CA 96001</p>
                                                </a>
                                            </dd>

                                            <dt class="col-2 text-muted">
                                                <mail-icon class="fea icon-sm"></mail-icon>
                                            </dt>
                                            <dd class="col-10 text-muted">
                                                <a href="mailto:contact@example.com" class="text-muted">info@landrick.com</a>
                                            </dd>

                                            <dt class="col-2 text-muted">
                                                <phone-icon class="fea icon-sm"></phone-icon>
                                            </dt>
                                            <dd class="col-10 text-muted">
                                                <a href="tel:+152534-468-854" class="text-muted">(+12) 1546-456-856</a>
                                            </dd>
                                        </dl>
                                    </div-->
                                    <!--end col-->
                                </div>
                                <!--end row-->
                            </div>

                            <div class=" py-4">
                              <div class="row justify-content-center">
                                <div class="col-md-9">
                                  <h6>Tu resultado es:</h6>
                                </div>
                              </div>

                              <div class="row mt-3 justify-content-center" v-for="message in result.fields.messages.intro">
                                <div class="col-md-9 text-justify">
                                  <div class="alert" :class="'alert-'+ message.color" role="alert"> 
                                    <h6>{{message.text}}</h6>
                                  </div>
                                </div>
                              </div>

                              <div class="row mt-3 justify-content-center">
                                <div class="col-12 text-center">
                                  <!--vue-speedometer 
                                    :width="325"
                                    :height="250"
                                    :minValue="result.fields.general_rate_min*0.6"
                                    :maxValue="result.fields.general_rate_max*1.05"
                                    :value="result.fields.general_rate"
                                    startColor="#2BE311"
                                    endColor="#F12707"
                                    :ringWidth="15"
                                    needleColor="#808B96"
                                    :needleHeightRatio="0.8"
                                    :maxSegmentLabels="0"
                                    :segments="60"
                                    :currentValueText="result.fields.general_rate_h"
                                  /-->
                                  <!--needleColor="#9860f3"-->
                                  <vue-speedometer 
                                    :forceRender="true"
                                    :width="290"
                                    :height="220"
                                    :minValue="sMinValue"
                                    :maxValue="sMaxValue"
                                    :value="sValue"
                                    :ringWidth="20"
                                    :customSegmentStops="sCustomSegment"
                                    :segmentColors="sCustomSegmentColor"
                                    :customSegmentLabels='[
                                      {
                                        text: "",
                                        position: "OUTSIDE",
                                        color: "#808B96"
                                      },
                                      {
                                        text: "",
                                        position: "OUTSIDE",
                                        color: "#808B96"
                                      },
                                      {
                                        text: "",
                                        position: "OUTSIDE",
                                        color: "#808B96"
                                      },
                                    ]'
                                    needleColor="#808B96"
                                    :needleHeightRatio="0.8"
                                    :currentValueText="result.fields.general_rate_h"
                                    valueTextFontWeight="bold"
                                    valueTextFontSize="18px"
                                    labelFontSize="13px"
                                  />
                                </div>
                                <!--div class="col-7 text-center">
                                  <VueSvgGauge
                                    :start-angle="-95"
                                    :end-angle="95"
                                    :value="89"
                                    :separator-step="25"
                                    :min="0"
                                    :max="100"
                                    :gauge-color="[{ offset: 0, color: '#2BE311'}, { offset: 33, color: '#F1DF07'},  { offset: 85, color: '#F17C07'}, { offset: 100, color: '#F12707'}]"
                                    :scale-interval="5"
                                  />
                                </div-->
                              </div>
                              <div class="row justify-content-center">
                                <div class="col-md-9">
                                  <h6>¿Qué significa esto en mi caso?</h6>
                                </div>
                                <div class="col-md-9">
                                  <p><ul>
                                    <li v-for="segment in sCustomSegmentMessage"><span class="badge" :style="'background-color: ' + segment.color + ' !important; color: ' + segment.textColor"> {{ segment.label }}: </span> {{ segment.message }}</li>
                                  </ul></p>
                                </div>
                              </div>
                              <div class="row mt-3 justify-content-center" v-for="message in result.fields.messages.intro2">
                                <div class="col-md-9 text-justify">
                                  <div class="alert" :class="'alert-'+ message.color" role="alert"> 
                                    <h6>{{message.text}}</h6>
                                  </div>
                                </div>
                              </div>

                              <!--div class="row mt-3 border-top justify-content-center">
                                <div class="col-md-10 mt-3 text-justify">
                                  ¿Qué puedes hacer al respecto?<br/><br/>
                                  Participar en este cuestionario y estimar tu factor de riesgo general ya es un primer paso.<br/><br/>
                                  Ahora toca conocer los factores que están contribuyendo a que tu riesgo aumente o disminuya y hacer algo al respecto.<br/><br/>
                                  También es importante saber si requieres tener un plan de tamizaje (exámenes preventivos), según las recomendaciones médicas más recientes.
                                </div>
                              </div-->
                              <div class="row mt-3 border-top justify-content-center" v-if="!emailChecked && reqEmailValidation === 'yes'">
                                <div class="col-md-10 mt-3 text-center">
                                  <h6>¿Quieres conocer ésta y otra información valiosa?</h6>
                                </div>
                                <div class="col-md-10 mt-3 text-justify">
                                  Inserta el código que te hicimos llegar a tu correo electrónico para obtener tu reporte completo.
                                </div>
                              </div>
                              <div class="row justify-content-center" v-if="!emailChecked && reqEmailValidation === 'yes'">
                                <form class="login-form mt-4" ref="emailcheck">
                                  <div class="row justify-content-center">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <div class="position-relative justify-content-center" style="display: flex; text-align: center;">
                                          
                                            <input type="number" class="form-control align-items-center mr-1 mr-md-2" required v-model="token1" @input="focusNext" @paste.prevent="onPaste" ref="firstToken" size="lg" autocomplete="off" style="width: 40px; height:50px;"/>
                                            <input type="number" class="form-control align-items-center mr-1 mr-md-2" required v-model="token2" @input="focusNext" @paste.prevent="onPaste" autocomplete="off" style="width: 40px; height:50px;"/>
                                            <input type="number" class="form-control align-items-center mr-2 mr-md-4" required v-model="token3" @input="focusNext" @paste.prevent="onPaste" autocomplete="off" style="width: 40px; height:50px;"/>
                                            
                                            <input type="number" class="form-control align-items-center mr-1 mr-md-2" required v-model="token4" @input="focusNext" @paste.prevent="onPaste" autocomplete="off" style="width: 40px; height:50px;"/>
                                            <input type="number" class="form-control align-items-center mr-1 mr-md-2" required v-model="token5" @input="focusNext" @paste.prevent="onPaste" autocomplete="off" style="width: 40px; height:50px;"/>
                                            <input type="number" class="form-control align-items-center mr-1 mr-md-2" required v-model="token6" @input="focusNext" @paste.prevent="onPaste" autocomplete="off" style="width: 40px; height:50px;"/>

                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div class="col-md-7 text-center" v-if="validatingToken">
                                      <b-spinner style="width: 3rem; height: 3rem;" variant="primary" label="Validando..."></b-spinner>
                                    </div>
                                    <div class="col-md-7 text-center text-muted" v-if="!validatingToken">
                                      Puedes copiar y pegar
                                    </div>
                                    <div class="col-md-7 mt-3" v-if="badToken">
                                      <div class="alert alert-danger text-center" role="alert">Código Incorrecto! :(</div>
                                    </div>
                                    <div class="col-md-7 mt-3" v-if="badPasted">
                                      <div class="alert alert-warning text-center" role="alert">Asegúrate de copiar el codigo de 6 dígitos de forma correcta antes de pegar</div>
                                    </div>
                                    <div class="col-md-7 mt-3 text-center" v-if="!validatingToken">
                                      <span v-if="canResendEmail">¿No recibiste tu correo?&nbsp;</span>
                                      <a href="javascript:void()" class="text-warning" v-if="canResendEmail" @click.prevent="reSendEmail">
                                        da click para enviar de nuevo
                                      </a>
                                      <span class="text-info" v-if="!canResendEmail">
                                        Puedes volver a reenviar en {{canResendEmailTimer}} segundos.
                                      </span>
                                    </div>
                                    <div class="col-md-7 mt-3 text-justify text-muted" v-if="!canResendEmail && !validatingToken">
                                      El correo fue enviado a {{contact_email}} desde "Duppla Doctor &lt;info&#64;duppla.doctor&gt;", revisa tu carpeta de SPAM ya que en ocasiones tu proveedor de correo puede enviarlo ahí. 
                                    </div>
                                    <div class="col-md-7 mt-3 text-center" v-if="canResendEmail && !validatingToken">
                                      ¿El correo <span class="text-primary">{{contact_email}}</span>, es incorrecto?<br/>
                                        <a href="javascript:void(0)" class="text-warning" v-b-modal.updateEmailModal>
                                          da click aquí para actualizarlo
                                        </a>
                                    </div>
                                  </div>
                                </form>
                              </div>
                              <span v-if="emailChecked || reqEmailValidation !== 'yes'">
                                <div class="row mt-3 border-top justify-content-center" ref="emailchecked" v-if="emailChecked">
                                  <div class="col-md-10 mt-3 mb-3 text-center">
                                    <h3>¡Gracias por validar tu cuenta! &nbsp;<i class="mdi mdi-check-circle text-success"></i></h3>
                                  </div>
                                  <!-- eliminar: div class="col-md-10 mt-3 mb-3 text-justify">
                                    A continuación encontrarás tu reporte detallado:
                                  </div-->
                                </div>

                                <!-- eliminar: div class="row mt-3 justify-content-center" v-for="message in result.fields.messages.intro">
                                  <div class="col-md-9 text-justify">
                                    <div class="alert" :class="'alert-'+ message.color" role="alert"> 
                                      <h6>{{message.text}}</h6>
                                    </div>
                                  </div>
                                </div-->

                                <!-- eliminar: div class="row mt-3 border-top justify-content-center">
                                  
                                  <div class="col-md-10 mt-3 mb-3">
                                    ¿De qué nos sirve saber esto? <br/><br/>
                                    <ol>
                                      <li>Hacer lo que esté a nuestro alcance para reducir el riesgo de desarrollar cáncer.</li>
                                      <li>En caso de que, pese a los esfuerzos, desarrollemos cáncer (tocamos madera) detectarlo lo más pronto posible.</li>
                                    </ol>
                                    ¿Cómo aplica esto en tu caso en particular? 
                                  </div>
                                </div -->
                                <!--div class="row mt-3 border-top justify-content-center">
                                  <div class="col-md-9 mt-3 text-center">
                                    <h4>
                                      Prevención &nbsp;<i class="mdi mdi-account-heart text-success"></i>
                                    </h4>
                                  </div>
                                  <div class="col-md-9 text-justify">
                                      Para prevenir el cáncer de forma efectiva, tenemos que conocer cuáles son los factores de riesgo que nos pueden hacer más propensos a la enfermedad. Algunos de estos factores no los podemos modificar (por ejemplo, tu edad) pero hay otros que sí. 
                                  </div>
                                </div-->

                                <!--form ref="form1">
                                  <div class="row justify-content-center mt-4">
                                    <div class="col-md-9 alert alert-light alert-pills" role="alert">
                                      <div class="col-md-12 mt-3" >
                                        <b-form-group label="¿Te gustaria recibir recomendaciones sobre cómo reducir aún más tu riesgo vía Whatsapp?" >
                                          <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="opt_adviceYes" name="opt_advice" class="custom-control-input" value="yes" v-model="opt_advice" required @input="updateProp"/>
                                            <label class="custom-control-label" for="opt_adviceYes">Sí</label>
                                          </div>
                                          <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="opt_adviceNo" name="opt_advice" class="custom-control-input" value="no" v-model="opt_advice" required @input="updateProp"/>
                                            <label class="custom-control-label" for="opt_adviceNo">No</label>
                                          </div>
                                        </b-form-group>
                                      </div>
                                    
                                      <div class="col-md-6" v-if="opt_advice === 'yes'">
                                        <div class="form-group">
                                          <label>Lada de Contacto <span class="text-danger">*</span></label>
                                          <div class="position-relative">
                                            <phone-forwarded-icon class="fea icon-sm icons"></phone-forwarded-icon>
                                            <b-select class="form-control pl-5" v-model="contact_phone_country" name="contact_phone_country" at-type="lookup" :options="countries" required text-field="textLada" value-field="value" @change.native="updateProp">
                                              <template #first>
                                                <b-select-option :value="null" disabled>-- Lada --</b-select-option>
                                              </template>
                                            </b-select>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-6" v-if="opt_advice === 'yes'">
                                        <div class="form-group">
                                          <label>Teléfono de Contacto <span class="text-danger">*</span></label>
                                          <div class="position-relative">
                                            <phone-icon class="fea icon-sm icons"></phone-icon>
                                            <input type="tel" class="form-control pl-5" placeholder="Teléfono de 10 dígitos" v-model="contact_phone" name="contact_phone" required maxlength="10" minlength="10" pattern="[0-9]{10}"/>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-6 mb-3 text-right" v-if="!showAll">
                                        <b-btn @click.prevent="showAllCheck" class="btn btn-primary">
                                          Continúa con recomendaciones aquí <corner-right-down-icon class="fea icon-sm icons"></corner-right-down-icon>
                                        </b-btn>
                                      </div>
                                    </div>
                                  </div>
                                </form-->
                                <div class="row mt-3 border-top justify-content-center" ref="tamizaje">
                                  <div class="col-md-9 mt-3 text-center">
                                    <h4>
                                      PLAN DE PREVENCIÓN PERSONALIZADO &nbsp;<i class="mdi mdi-medical-bag text-primary"></i>
                                    </h4>
                                  </div>
                                  <div class="col-md-9 text-justify" v-if="result.fields.messages.tamizaje.length > 0">
                                    Según tu perfil de riesgo, es muy importante que consideres este plan:
                                  </div>
                                  <div class="col-md-9 text-justify" v-if="result.fields.messages.tamizaje.length === 0">
                                    ¡Buenas noticias! <br/><br/>
                                    Dado tu perfil de riesgo, aún no es necesario realizarte estudios de prevención (no requieres laboratorios o estudios de imagen).
                                    <br/><br/>
                                    Aun así, recuerda que síntomas como: palpar una bolita nueva en tu cuerpo, pérdida de peso no intencionada, fiebre no relacionada a infección, pueden ser algunos de los síntomas inespecíficos de cáncer.
                                    <br/><br/>
                                    Si desarrollas alguno de estos síntomas, te podemos asesorar vía <a href="http://onco.chat/" target="_blank">http://onco.chat/</a>
                                  </div>

                                </div>
                                <div class="row mt-3 justify-content-center" v-for="(message, index) in result.fields.messages.tamizaje">
                                    <div class="col-md-9 text-justify" v-if="showTamizaje(index)" :ref="'tamizaje' + index">
                                      <div class="col-md-12 mt-3">
                                        <legend>{{message.text_prev}}</legend>
                                      </div>
                                      <div class="col-md-12 mt-3">
                                        <b-form-group :label="message.screening_question">
                                          <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" :id="index+'Yes'"  :name="'screening'+index" class="custom-control-input" value="yes" required v-model="result.fields.messages.tamizaje[index].screening_answer" @input="screeningAnswer($event, message, index)" />
                                            <label class="custom-control-label" :for="index+'Yes'">Sí</label>
                                          </div>
                                          <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" :id="index+'No'"  :name="'screening'+index" class="custom-control-input" value="no" required v-model="result.fields.messages.tamizaje[index].screening_answer" @input="screeningAnswer($event, message, index)" />
                                            <label class="custom-control-label" :for="index+'No'">No</label>
                                          </div>
                                        </b-form-group>
                                      </div>
                                    </div>
                                    <div class="col-md-9 text-justify" v-show="result.fields.messages.tamizaje[index].screening_answer === 'yes'">
                                      <div class="alert" :class="'alert-success'" role="alert"> 
                                        <h6>
                                          {{message.text_yes}}
                                        </h6>
                                      </div>
                                    </div>
                                    <div class="col-md-9 text-justify" v-show="result.fields.messages.tamizaje[index].screening_answer === 'no'">
                                      <div class="alert" :class="'alert-'+ message.color" role="alert"> 
                                        <h6>
                                          {{message.text}}
                                        </h6>
                                      </div>
                                    </div>
                                </div>

                                <!-- v-if="!scrCompleted && result.fields.messages.tamizaje.length > 0" -->
                                <div class="row justify-content-center" v-if="!scrCompleted && result.fields.messages.tamizaje.length > 0" style="height: 90px;">
                                  <div class="col-md-9 text-center">
                                    <small class="text-muted">Contesta las preguntas para continuar</small>
                                  </div>
                                  <div class="col-md-9 text-center">
                                    <transition name="fade">
                                      <span v-if="showScrArrow">
                                        <!--i class="mdi mdi-arrow-down text-primary icon-lg"/-->
                                        <arrow-down-icon class="fea icon-lg icons text-primary"></arrow-down-icon>
                                      </span>
                                    </transition>
                                  </div>
                                </div>

                                <div class="row mt-3 justify-content-center" v-if="scrCompleted">
                                  <div class="col-md-9 text-justify" v-if="result.fields.messages.tamizaje.length > 0">
                                    Al final encontrarás los factores de riesgo en los que se basa tu programa.
                                  </div>
                                </div>

                                <!-- form ref="form2" v-if="result.fields.messages.tamizaje.length > 0 && scrCompleted && !scrYesAll">
                                  <div class="row justify-content-center mt-4">
                                    <div class="col-md-9 alert alert-light alert-pills" role="alert">
                                      <div class="col-md-12 mt-3">
                                        <b-form-group  label="¿Te interesaría contratar un seguro que cubra las consultas, los estudios, medicamentos, operaciones y procedimientos necesarios para enfrentar el cáncer?" >
                                          <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="opt_insuranceYes" name="opt_insurance" class="custom-control-input" value="yes" v-model="opt_insurance" required @input="updateProp"/>
                                            <label class="custom-control-label" for="opt_insuranceYes">Sí</label>
                                          </div>
                                          <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="opt_insuranceNo" name="opt_insurance" class="custom-control-input" value="no" v-model="opt_insurance" required @input="updateProp"/>
                                            <label class="custom-control-label" for="opt_insuranceNo">No</label>
                                          </div>
                                        </b-form-group>
                                      </div>
                                      <Se eliemina según #10 div class="col-md-12 mb-3">
                                        <b-form-group label="¿Te gustaría recibir información de dónde te puedes realizar el tamizaje recomendado? " >
                                          <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="opt_screeningYes" name="opt_screening" class="custom-control-input" value="yes" v-model="opt_screening" required @input="updateProp"/>
                                            <label class="custom-control-label" for="opt_screeningYes">Sí</label>
                                          </div>
                                          <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="opt_screeningNo" name="opt_screening" class="custom-control-input" value="no" v-model="opt_screening" required @input="updateProp"/>
                                            <label class="custom-control-label" for="opt_screeningNo">No</label>
                                          </div>
                                        </b-form-group>
                                      </div>
                                    </div>
                                  </div>
                                </form-->
                                <span v-if="scrCompleted">
                                  <div class="row mt-3 border-top justify-content-center">
                                    <div class="col-md-9 mt-3 text-center">
                                      <h4>
                                        REDUCE TU RIESGO &nbsp;<i class="mdi mdi-weight-lifter text-info"></i>
                                      </h4>
                                    </div>
                                    <div class="col-md-9 text-justify" v-if="result.fields.messages.factores_modificables.length > 0">
                                        <h6>Dado tu perfil, hay acciones que puedes emprender para reducir la probabilidad de tener cáncer:</h6>
                                    </div>
                                    <div class="col-md-9 text-justify" v-if="result.fields.messages.factores_modificables.length === 0">
                                        <h6>Con base en tus respuestas, observamos que llevas un estilo de vida saludable. ¡Sigue así para limitar el riesgo de desarrollar cáncer! </h6>
                                    </div>
                                  </div>
                                  <div class="row mt-3 justify-content-center" v-for="message in result.fields.messages.factores_modificables">
                                    <div class="col-md-9 text-justify">
                                      <div class="alert" :class="'alert-'+ message.color" role="alert"> 
                                        <h6>{{message.text}}</h6>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mt-5 border-top justify-content-center">
                                    <div class="col-md-9 mt-3 text-justify">
                                      <h6>
                                        Los factores de riesgo no modificables que impactan tu perfil y en el cual está basado tu programa de tamizaje:
                                      </h6>
                                    </div>
                                  </div>
                                  <div class="row mt-3 justify-content-center" v-for="message in result.fields.messages.factores_no_modificables">
                                    <div class="col-md-9 text-justify">
                                      <div class="alert" :class="'alert-'+ message.color" role="alert"> 
                                        <h6>{{message.text}}</h6>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row justify-content-center mt-3 mb-5" v-if="result.fields.organization_reshare === 'yes'">
                                    <div class="col-md-9 mt-3 text-justify">
                                      ¿Te gustaría ayudar a prevenir el cáncer en tu familia y círculo de amigos? 
                                    </div>
                                    <div class="col-md-9 mt-4">
                                      <div class="row">
                                        <div class="col-md-6 mt-2 text-center">
                                          <b-btn class="btn btn-md" @click.prevent="copyUrl" style="background-color: #7D7667; padding-top: 3px; padding-bottom: 3px; width: 170px; text-align: start; ">
                                            <i class="mdi mdi-content-copy icon-lg" style="color: white; font-size: x-large;"> </i> &nbsp;
                                            <span style="color: white;">Copiar URL</span>
                                          </b-btn>
                                        </div>
                                        <div class="col-md-6 mt-2 text-center" v-for="network in networks">
                                          <ShareNetwork :network="network.network" :url="sharing.url"
                                            :title="sharing.title"
                                            :description="sharing.description"
                                            :quote="sharing.quote"
                                            :hashtags="sharing.hashtags" :popup="{width: 828, height: 626}">
                                              <span class="btn btn-md" :style="'background-color:' + network.color + ';'" style="padding-top: 3px; padding-bottom: 3px; width: 170px; text-align: start; ">
                                                <i :class="network.icon" style="color: white; font-size: x-large;"> </i> &nbsp;
                                                <span style="color: white;">{{network.name}}</span>
                                              </span>
                                          </ShareNetwork>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mt-5 border-top justify-content-center">
                                    <div class="col-md-9 mt-3 text-center">
                                      <div class="row mt-3 justify-content-center">
                                        <div class="text-justify">
                                          La diferencia entre la vida y la muerte en una situación de cáncer es que se pueda recibir una atención oportuna y adecuada. 
                                        </div>
                                      </div>
                                      <br>
                                      <b-btn @click.prevent="print" class="btn btn-primary">
                                        <printer-icon class="fea icon-sm icons"></printer-icon> Imprime tu reporte
                                      </b-btn>
                                    </div>
                                    <div class="col-md-9 mt-3 text-center">
                                      <a class="btn btn-primary" :href="urlReturnConoce" target="_blank">
                                        Conoce tu beneficio
                                      </a>
                                    </div>
                                  </div>
                                </span>
                              </span>


                                <!--div class="row mb-0">
                                    <div class="col-md-12 order-2 order-md-1">
                                        <dl class="row">
                                            <dt class="col-md-3 col-5 font-weight-normal">Invoice No. :</dt>
                                            <dd class="col-md-9 col-7 text-muted">{{result.fields.contact_email}}</dd>

                                            <dt class="col-md-3 col-5 font-weight-normal">Name :</dt>
                                            <dd class="col-md-9 col-7 text-muted">Calvin Carlo</dd>

                                            <dt class="col-md-3 col-5 font-weight-normal">Address :</dt>
                                            <dd class="col-md-9 col-7 text-muted">
                                                <p class="mb-0">1962 Pike Street,</p>
                                                <p>Diego, CA 92123</p>
                                            </dd>

                                            <dt class="col-md-3 col-5 font-weight-normal">Phone :</dt>
                                            <dd class="col-md-9 col-7 text-muted">(+45) 4584-458-695</dd>
                                        </dl>
                                    </div>

                                    <div class="col-md-4 order-md-2 order-1 mt-2 mt-sm-0">
                                        <dl class="row mb-0">
                                            <dt class="col-md-4 col-5 font-weight-normal">Date :</dt>
                                            <dd class="col-md-8 col-7 text-muted">15th Oct, 2019</dd>
                                        </dl>
                                    </div>
                                </div-->
                            </div>

                            <!--div class="invoice-table pb-4">
                                <div class="table-responsive bg-white shadow rounded">
                                    <table class="table mb-0 table-center invoice-tb">
                                        <thead class="bg-light">
                                            <tr>
                                                <th scope="col" class="text-left">No.</th>
                                                <th scope="col" class="text-left">Item</th>
                                                <th scope="col">Qty</th>
                                                <th scope="col">Rate</th>
                                                <th scope="col">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row" class="text-left">1</th>
                                                <td class="text-left">Inclusive Insights</td>
                                                <td>2</td>
                                                <td>$ 2600</td>
                                                <td>$ 5200</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" class="text-left">2</th>
                                                <td class="text-left">Handy Guide</td>
                                                <td>1</td>
                                                <td>$ 3660</td>
                                                <td>$ 3660</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" class="text-left">3</th>
                                                <td class="text-left">Premiere Product</td>
                                                <td>3</td>
                                                <td>$ 4580</td>
                                                <td>$ 13740</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="row">
                                    <div class="col-lg-4 col-md-5 ml-auto">
                                        <ul class="list-unstyled h5 font-weight-normal mt-4 mb-0">
                                            <li class="text-muted d-flex justify-content-between">Subtotal :<span>$ 22600</span></li>
                                            <li class="text-muted d-flex justify-content-between">Taxes :<span> 0</span></li>
                                            <li class="d-flex justify-content-between">Total :<span>$ 22600</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div-->

                            <div class="invoice-footer border-top pt-4">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="text-sm-left text-muted text-center">
                                            <h6 class="mb-0">Contáctanos a: <a href="email:hola@duppla.doctor" class="text-warning">asesor@duppla.doctor</a></h6>
                                        </div>
                                    </div>

                                    <!--div class="col-sm-6">
                                        <div class="text-sm-right text-muted text-center">
                                            <h6 class="mb-0">
                                                <router-link to="/page-terms" target="_blank" class="text-primary">Terms & Conditions</router-link>
                                            </h6>
                                        </div>
                                    </div-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Invoice End -->
    <!--end section-->
    <!--Footer/-->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
    <b-modal ref="updateEmailModal" id="updateEmailModal" title="Actualiza tu correo" centered>
      <div class="bg-white p-3 rounded box-shadow">
        <form ref="emailForm">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Correo electrónico <span class="text-danger">*</span></label>
                <div class="position-relative">
                  <mail-icon class="fea icon-sm icons"></mail-icon>
                  <input type="email" class="form-control pl-5" placeholder="Email" v-model="contact_email_update" required/>
                </div>
              </div>
            </div>
          </div>
        </form>

        <p class="text-muted mb-0">
          Por favor asegúrate de escribirlo correctamente, al actualizar te enviaremos inmediatamente un correo con tu token
        </p>
      </div>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-btn @click="cancel()" class="btn btn-secondary" :disabled="updating">
          Cancelar
        </b-btn>
        <b-btn @click.prevent="updateEmail" class="btn btn-primary" :disabled="updating">
          {{ (updating?'Actualizando...':'Actualizar') }}
        </b-btn>
      </template>
      <!--div slot="modal-footer" class="w-100">
        <b-btn @click="deleteObject(fileNameToDelete)" class="btn btn-primary">
          Eliminar
        </b-btn>
        <b-btn class="btn btn-secondary">
          Cancelar
        </b-btn>
      </div-->
    </b-modal>
    <b-modal ref="copyModal" id="copyModal" title="Compartir" centered>
      <div class="bg-white p-3 rounded box-shadow">
        <p class="text-muted mb-0">
          ¡La url se ha copiado a tu portapapeles!<br/><br/>
          Sólo tienes que pegar el contenido en tu medio preferido y compartir.
        </p>
      </div>
    </b-modal>
</div>
</template>
